<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
      size="lg"
      class="basic-modal-color"
    >   
      <CRow>      
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="branchs"
            :fields="fields"
            :dark="dark"
            :items-per-page="15"
            pagination
            style="width: 100%; font-size: 10px;"
            :table-filter="{label: 'Buscar: ', placeholder: '...'}"
          >           
          <template #branch_name="{item}"><td>{{item.branch_name}}</td></template>
              <template #total="{item}">
                <td class="text-center w50">
                  {{item.total}}
                </td>
              </template>
              <template #done="{item}">
                <td class="text-center w50">
                  {{item.done}}
                </td>
              </template>
              <template #validated="{item}">
                <td class="text-center w50">
                  {{item.validated}}
                </td>
              </template>
              <template #rejected="{item}">
                <td class="text-center w50">
                  {{item.rejected}}
                </td>
              </template>
              <template #pending="{item}">
                <td class="text-center w50">
                  {{item.pending}}
                </td>
              </template>
          </CDataTable>        
        </CCol>              
      </CRow>      
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store';
import ws from '../../../services/graphs';

export default {
  name: 'BranchsKamsModal',
  props: {
    fields: {
      type: Array,
      default () {
        return [
          {key: 'branch_name', label: 'Sucursal'},
          {key: 'total', label: 'Total Visitas' ,_style:" width: 50px;"},
          {key: 'done', label: 'Visitas Terminadas',_style:" width: 50px;"},
          {key: 'validated', label: 'Visitas Aceptadas', _style:" width: 50px;"},
          {key: 'rejected', label: 'Visitas Rechazadas', _style:" width: 50px;"},
          {key: 'pending', label: 'Visitas Pendientes', _style:" width: 50px;"},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  components: {
  },
  data () {
    return {
      showModal: false,
      title: "Detalle Marca-Sucursal",
      color: "info",
      submitted: false,      
      branchs: [],      
    }
  },
  mounted: async function() {    
    
  },
  methods: {
    async showBranchsKamsModal (params) {
      this.loading();
        this.showModal = true;
        this.color = "info";
        this.title = 'Detalle Marca-Sucursal';
        this.isEdit = false;
        this.submitted = false;
      
        let response = await ws.branchsDetailKamsBoss(params);
        if(response.type == "success"){
          this.branchs = response.data.customers;
        }
      this.loaded();

    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
    },
    getEmptyForm () {
      return {
          id: 0,
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
}
</script>