<template>
  <div>
    <GraphsMenu menu="kams-boss"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Marcas Kams (Jefe)
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
                <CButton color="success" size="sm" @click="downloadExcel">Descargar Excel</CButton>
              </CCol>
              <CCol md="4" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>
              <CCol md="5" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="kamSelect" 
                  :options="kamsOptions"
                  @change="changeFilters"
                />   
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="6">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="customers"
              :fields="fields"
              :items-per-page="15"
              :dark="false"
              pagination
              style="width: 100%; font-size: 10px;"
              :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            >
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #total="{item}">
                <td class="text-center w50">
                  <template v-if="item.total > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.total}}</a>  
                  </template>
                  <template v-else>
                    {{item.total}}
                  </template>                  
                </td>
              </template>
              <template #done="{item}">
                <td class="text-center w50">
                  <template v-if="item.done > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.done}}</a>  
                  </template>
                  <template v-else>
                    {{item.done}}
                  </template>
                </td>
              </template>
              <template #validated="{item}">
                <td class="text-center w50">
                  <template v-if="item.validated > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.validated}}</a>  
                  </template>
                  <template v-else>
                    {{item.validated}}
                  </template>
                </td>
              </template>
              <template #rejected="{item}">
                <td class="text-center w50">
                  <template v-if="item.rejected > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.rejected}}</a>  
                  </template>
                  <template v-else>
                    {{item.rejected}}
                  </template>
                </td>
              </template>
              <template #pending="{item}">
                <td class="text-center w50">
                  <template v-if="item.pending > 0">
                    <a  href="#" @click="openDetailBranchModal(item.customer_id)">{{item.pending}}</a>  
                  </template>
                  <template v-else>
                    {{item.pending}}
                  </template>
                </td>
              </template>
            </CDataTable>
          </CCol>
          <CCol md="6">
              <CRow>           
                <CCol md="12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </CCol>                     
              </CRow>
              <CRow>           
                <CCol md="4">
                  <CWidgetSimple style="height: 125px;" header="Total Visitas" :text="String(metrics.total)"></CWidgetSimple>
                </CCol>     
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.finished)"
                    text="Visitas Terminadas"
                    color="success"
                    :value="Number(metrics.finished_percent)"
                    >
                    </CWidgetProgress>
                </CCol>  
                <CCol md="4">
                  <CWidgetProgress
                    style="height: 125px;"
                    :header="String(metrics.finished_kam)"
                    text="Visitas Validadas"
                    color="success"
                    :value="Number(metrics.finished_kam_percent)"
                    >
                    </CWidgetProgress>
                </CCol>   
              </CRow>
              <CRow>     
                <CCol md="4">
                  <GaugePercentSemaforoGraph ref="graphPercent" title="Visitas Aceptadas" ></GaugePercentSemaforoGraph>
                </CCol>                                   
                <CCol md="4">
                  <DonoutSemaforoGraph ref="graphDonout" title="Visitas Terminadas" isMini="1"></DonoutSemaforoGraph>
                </CCol>  
                <CCol md="4">
                  <GaugeSemaforoGraph ref="graphGauge" title="Visitas Validadas" isMini="1"></GaugeSemaforoGraph>
                </CCol>      
              </CRow>
          </CCol>          
        </CRow>
      </CCardBody>
    </CCard>
    <DetailBranchModal ref="detailBranchModal"></DetailBranchModal>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import DonoutSemaforoGraph from './graphs/DonoutSemaforoKams.vue';
import GaugeSemaforoGraph from './graphs/GaugeSemaforo.vue';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforo.vue';
import GraphsMenu from './GraphsMenu.vue';
import DetailBranchModal from './modals/BranchsKamsModal.vue';

export default {
  name: 'GraphsKamsBoss',
  components: {
    DonoutSemaforoGraph,
    GaugeSemaforoGraph,
    GaugePercentSemaforoGraph,
    GraphsMenu,
    DetailBranchModal
  },
  data () {
    return {
      code: 'general',
      customers: [],
      fields: [
          {key: 'customer_name', label: 'Marca'},
          {key: 'total', label: 'Total Visitas' ,_style:" width: 50px;"},
          {key: 'done', label: 'Visitas Terminadas',_style:" width: 50px;"},
          {key: 'validated', label: 'Visitas Aceptadas', _style:" width: 50px;"},
          {key: 'rejected', label: 'Visitas Rechazadas', _style:" width: 50px;"},
          // {key: 'pending', label: 'Visitas Pendientes', _style:" width: 50px;"},
        ],
      
      kamSelect: "",
      kamsOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
      dataGraphs: [],
      graphs_gauge: 0,
      semaforo: 0,
      graphs_percent:0,
      graphs_donout:[{code: '',service : "",total : "0",}],
      metrics:{
        total : "0",
        finished : "0",
        finished_percent: "0",
        finished_kam : "0",
        finished_kam_percent: "0",
      }
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    let response = await ws.getKamsBoss(); 
    if(response.type == "success"){
      this.kamsOptions = await this.$parseSelectOptions(response.data, "id", "name",[]);
      if(response.data.length > 0){
        this.kamSelect = this.kamsOptions[0].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
        };
        let response = await ws.getMetricsKamsBoss(filters);
        if(response.type == "success"){
          this.metrics = response.data;          
        }
        let response_graphs = await ws.getGraphsKamsBoss(filters);
        if(response_graphs.type == "success"){
          this.dataGraphs = response_graphs.data;  
          this.customers = response_graphs.data.customers;        
          this.semaforo = response_graphs.data.semaforo;      
          this.graphs_percent = response_graphs.data.graph_percent;      
          this.graphs_donout = response_graphs.data.graph_donout;     
          this.graphs_gauge = response_graphs.data.graph_guage;
        }
        this.chargeGraphs();
      this.loaded();
    },
    async chargeGraphs(){
        await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        await this.$refs.graphDonout.loadGraph(this.graphs_donout);
        await this.$refs.graphGauge.loadGraph(this.graphs_gauge);
    },
    async openDetailBranchModal(customer_id){
      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id: customer_id
        };
        await this.$refs.detailBranchModal.showBranchsKamsModal(filters);
    },
    async downloadExcel(){
      this.loading();

      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
        };
      await ws.downloadExcelKamsBoss(filters);
      
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
